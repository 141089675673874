
import { getToken, setToken, removeToken } from '@/utils/auth'

const user = {
    state: {
        token: getToken(),
        name: '',
        avatar: '',
        roles: [],
        permissions: [],
        reaumeFile:{},
        id: ''
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_NAME: (state, name) => {
            state.name = name
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        },
        SET_PERMISSIONS: (state, permissions) => {
            state.permissions = permissions
        },
        SET_REAUMEFILE: (state, reaumeFile) => {
            state.reaumeFile = reaumeFile
        },
        SET_NICKNAME: (state, nickName) => {
            state.nickName = nickName
        },
        SET_ID: (state, id) => {
            state.id = id
        },
    },

    actions: {
    }
}

export default user
