import request from '@/utils/request'

/** 平台列表 **/
export function platformList(query) {
    return request({
        url: '/analyse/getPlatformList',
        method: 'get',
        params: query
    })
}
export function platformChart(query) {
    return request({
        url: '/echarts/getPlatformChart',
        method: 'get',
        params: query
    })
}



/** 地域列表 **/
export function provinceList(query) {
    return request({
        url: '/analyse/getProvinceList',
        method: 'get',
        params: query
    })
}
export function provinceChart(query) {
    return request({
        url: '/echarts/getProvinceChart',
        method: 'get',
        params: query
    })
}

/** 接口列表 **/
export function serviceList(query) {
    return request({
        url: '/analyse/getServiceList',
        method: 'get',
        params: query
    })
}
export function serviceChart(query) {
    return request({
        url: '/echarts/getServiceChart',
        method: 'get',
        params: query
    })
}
