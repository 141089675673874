<template>
    <div style="padding: 20px 30px">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="操作平台" name="first"></el-tab-pane>
            <el-tab-pane label="地域" name="second"></el-tab-pane>
            <el-tab-pane label="接口" name="third"></el-tab-pane>
        </el-tabs>
        <dataStatistics ref="dataStatistics"/>
    </div>
</template>

<script>
import dataStatistics from "@/views/dataStatistics.vue";
export default {
    name: "Index",
    components: {dataStatistics},
    data() {
        return {
            activeName: 'first'
        }
    },
    mounted() {
        this.$refs.dataStatistics.getList('first')
        this.$refs.dataStatistics.$refs.chartData.init('first')
    },
    methods: {
        handleClick(tab){
            this.activeName = tab.name
            this.$refs.dataStatistics.queryParams.param = ''
            this.$refs.dataStatistics.queryParams.pageNum = 1
            this.$refs.dataStatistics.queryParams.pageSize = 10
            this.$refs.dataStatistics.dateRange = []
            this.$refs.dataStatistics.getList(this.activeName)
            this.$refs.dataStatistics.$refs.chartData.init(this.activeName)
        },
    }
}
</script>

<style scope lang="scss">

</style>
