<template>
    <div>
       <el-card>
           <div class="title">{{title}}</div>
           <div class="roundChart" id="myCharts"></div>
       </el-card>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import {platformChart, provinceChart, serviceChart} from "@/api";
export default {
    name: "ChartData",
    data() {
        return {
            title: '',
            myChart: null,
        }
    },
    mounted() {
        window.onresize = () => {
            this.myChart.resize();
        };
    },
    methods: {
        // 操作平台
        init(valName){
            if(this.myChart){
                this.myChart.clear();
            }
            // 基于准备好的dom，初始化echarts实例
            this.myChart = echarts.init(document.getElementById('myCharts'));
            if(valName == 'third'){
                this.title = '最近7天接口平均响应时间排行top10(不含当天)'
                serviceChart().then(res => {
                    let data = res.data.map(item => item.key);
                    let value = res.data.map(item => item.value)
                    // 指定图表的配置项和数据
                    let option = {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        grid: {
                            top: '6%',
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: {
                            type: 'value',
                            // boundaryGap: [0, 0.01]
                            axisLine: {
                                lineStyle: {
                                    color: 'transparent' // 设置轴线颜色为透明
                                }
                            },
                            splitLine: {
                                show: false,
                            },
                        },
                        yAxis: {
                            type: 'category',
                            data: data,
                            axisTick: {
                                show: false // 设置为false以隐藏刻度线
                            },
                            axisLine: {
                                show: false, // 设置为false以去除Y轴线
                            },
                        },
                        series: [
                            {
                                type: 'bar',
                                data: value,
                                label: {
                                    show: true,
                                    position: 'inside',
                                    color:'white'
                                },
                            },
                        ]
                    };
                    // 使用刚指定的配置项和数据显示图表。
                    this.myChart.setOption(option);
                })
            } else {
                if(valName == 'first'){
                    this.title = '最近7天操作平台页面访问流量占比(不含当天)'
                    platformChart().then(res => {
                        let data = res.data.map(item => {
                            return {value: item.value, name: item.key}
                        })
                        this.awaitInterfase(data)
                    })
                } else if(valName == 'second'){
                    this.title = '最近7天地域页面访问流量占比(不含当天)'
                    provinceChart().then(res => {
                       let data = res.data.map(item => {
                            return {value: item.value, name: item.key}
                        })
                        this.awaitInterfase(data)
                    })
                }
            }
        },
        // 备份方法
        awaitInterfase(data){
            let option = {
                tooltip: {
                    trigger: 'item',
                },
                color: ['#5c8df4', '#5bcea3','#5d7092'],
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: '80%',
                        data: data,
                        labelLine: {
                            show: false // 设置为false以去掉指示线
                        },
                        label: {
                            normal: {
                                color: '#fff',
                                show: true,
                                position: 'inside', // 设置文本显示在扇叶内部
                                formatter: '{b}\n{d}%' // 自定义标签的格式
                            }
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
            this.myChart.setOption(option);
        }
    }
}
</script>

<style scope lang="scss">
.title{
    text-align: center;
}
#myCharts{
    width: 100%;
    height: 300px;
}
.el-card.is-always-shadow{
    box-shadow: none!important;
}
</style>
