<template>
    <el-row :gutter="24">
        <el-col :span="16">
            <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch"
                     label-width="68px">
                <el-form-item label="时间">
                    <el-date-picker
                        v-model="dateRange"
                        style="width: 240px"
                        value-format="yyyy-MM-dd"
                        type="daterange"
                        range-separator="-"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item :label="titleName === 'second'? '省份' : titleName === 'third' ? '接口' : '操作平台'" prop="param">
                    <el-select v-model="queryParams.param" placeholder="请选操作平台" clearable
                               style="width: 100%" v-if="titleName === 'first'">
                        <el-option label="PC" value="PC"/>
                        <el-option label="IOS" value="IOS"/>
                        <el-option label="Android" value="Android"/>
                    </el-select>
                    <el-input v-model="queryParams.param" placeholder="请输入省份" v-if="titleName === 'second'"></el-input>
                    <el-select v-model="queryParams.param" placeholder="请选择接口" clearable
                               style="width: 100%" v-if="titleName === 'third'">
                        <el-option label="ASR" value="ASR"/>
                        <el-option label="TTS" value="TTS"/>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">搜索</el-button>
                    <el-button icon="el-icon-refresh" size="small" @click="resetQuery">重置</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="dataList" v-loading="loading">
                <el-table-column label="日期" prop="ctime" :show-overflow-tooltip="true"  align="center"/>
                <el-table-column
                    :label="titleName === 'second'? '省份' : titleName === 'third' ? '接口' : '操作平台'"
                    :prop="titleName === 'second'? 'province' : titleName === 'third' ? 'serviceName' : 'platform'" :show-overflow-tooltip="true"  align="center"/>
                <el-table-column label="用户访问次数" prop="userCount" :show-overflow-tooltip="true" v-if="titleName !== 'third'" align="center"/>
                <el-table-column label="页面访问次数" prop="pageCount" :show-overflow-tooltip="true" v-if="titleName !== 'third'" align="center"/>
                <el-table-column label="平均响应时间（s）" prop="usedTimes" :show-overflow-tooltip="true" v-if="titleName === 'third'" align="center"/>
            </el-table>
            <pagination
                v-show="total>0"
                :total="total"
                :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize"
                @pagination="getList(titleName)"
            />
        </el-col>
        <el-col :span="8">
            <chartData ref="chartData"></chartData>
        </el-col>
    </el-row>
</template>

<script>
import chartData from "@/views/chartData.vue";
import {platformList, provinceList, serviceList} from "@/api";

export default {
    name: "DataStatistics",
    components: {chartData},
    data() {
        return {
            // 显示搜索条件
            showSearch: true,
            loading: false,
            queryParams:{
                pageNum: 1,
                pageSize: 10,
                param: undefined,
                startTime: undefined,
                endTime: undefined,
            },
            dateRange: [], // 绑定的日期范围
            dataList: [],
            total: 0,
            titleName: '',
            pickerMinDate: null,
            pickerMaxDate: null,
            pickerOptions: {
                onPick: ({ maxDate, minDate }) => {
                    if (minDate && this.pickerMinDate) {
                        this.pickerMinDate = null;
                    } else if (minDate) {
                        this.pickerMinDate = minDate.getTime();
                    }
                },
                disabledDate: (time) => {
                    if (this.pickerMinDate) {
                        const day1 =  29 * 24 * 3600 * 1000
                        let maxTime = this.pickerMinDate + day1
                        let minTime = this.pickerMinDate - day1
                        return time.getTime() > maxTime || time.getTime() < minTime
                    } else {
                        return false
                    }
                },
            },
        }
    },
    mounted() {

    },
    methods: {
        /** table数据 **/
        getList(tabName){
            this.titleName = tabName
            this.loading = true
            let params = {
                ...this.queryParams,
                startTime: this.dateRange !== null ? this.dateRange[0] : '',
                endTime: this.dateRange !== null ? this.dateRange[1] : ''
            }
            const apiMap = {
                'first': platformList,
                'second': provinceList,
                'default': serviceList
            }
            const result = (apiMap[tabName] || apiMap.default)(params);
            result.then(res => {
                if(res.code === 200){
                    this.dataList = res.rows
                    this.total = res.total
                    this.loading = false
                }else{
                    this.loading = false
                }
            }).catch(err => {
                this.loading = false
            })
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList(this.titleName);
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.dateRange = []
            this.handleQuery();
        },
    }
}
</script>

<style scope lang="scss">
.el-pagination{
    float: right;
    margin-top: 20px;
}
</style>
