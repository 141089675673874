import Cookies from 'js-cookie'

const TokenKey = 'base-Token'
// const TokenKey = 'MwU1PnknIVmxwIe54sSZqyyq85jF1rM4xmvoWGDTYzNzajCbJXzwvGq7oPzv6DH0wtZHGYMbSYrBU51KibMRMt4Lctg2Ubuce4VxJ9SocvnikCmnBraJ5GuMmZUbxslz'

export function getToken() {
  return Cookies.get(TokenKey)
  // return TokenKey
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
