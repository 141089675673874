import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import {  resetForm } from "@/utils/publicMethod";

// 分页组件
import Pagination from "@/components/Pagination";

Vue.use(ElementUI)
Vue.component('Pagination', Pagination)

Vue.prototype.resetForm = resetForm
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
